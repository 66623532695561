import React from 'react';

function Marquee() {
  return (
    <div>
      <div class="Marquee">
        <div class="Marquee-content">
          <a href="#" class="Marquee-tag">
            1
          </a>
          <a href="#" class="Marquee-tag">
            2
          </a>
          <a href="#" class="Marquee-tag">
            3
          </a>
          <a href="#" class="Marquee-tag">
            4
          </a>
          <a href="#" class="Marquee-tag">
            5
          </a>
          <a class="Marquee-tag">6</a>
          <div class="Marquee-tag">7</div>
          <div class="Marquee-tag">8</div>
          <div class="Marquee-tag">9</div>
          <div class="Marquee-tag">10</div>
          <div class="Marquee-tag">11</div>
          <div class="Marquee-tag">12</div>
        </div>
      </div>
    </div>
  );
}

export default Marquee;
