import React from 'react';
import { FaAngellist } from 'react-icons/fa';
import Project from './Project';

const About = () => {
  const [header] = React.useState({
    subHeader: 'About Me',
    text: 'Area of Specialization: Advanced Control  System, Product/ Prototype Design, Robotics, Project Proposal Writing for Fund, Patent Filing, Startup Creation, Technology/Project/Innovation Management',
    specializedHead: 'Current Additional Professional Status',
    specializedBody: `(i) Executive Council Member, MAKAUT, W.B (West Bengal University of Technology) Govt. of W.B.
                      (ii) Convener, Institutional Innovation Council (IIC) -JISCE under MHRD Innovation Cell (Government of India) at JIS College of Engineering.
(iii) Visiting Scientist, Institute of Coal, Federal Research Center Coal and Coal Chemistry Siberian Branch Russian Academy of Science.`,
  });
  const [state] = React.useState([
    { id: 1, title: 'Name:', text: ' Dr. Biswarup Neogi' },
    { id: 2, title: 'Email:', text: ' biswarup.neogi@jiscollege.ac.in' },
    { id: 3, title: 'Phone:', text: ' +91 9477751235' },
    { id: 4, title: 'Linkedin:', text: ' dr-biswarup-neogi' },
  ]);
  return (
    <div className="about">
      <div className="container">
        <div className="common">
          {/* <h1 className="mainHeader">{header.subHeader}</h1>
          <p className="mainContent">{header.text}</p> */}
          <div className="commonBorder"></div>
        </div>
        <div className="row  h-650 alignCenter">
          <div className="col-6">
            <div className="about__img">
              <Project />
            </div>
          </div>
          <div className="col-6">
            <div className="about__info">
              <h1>
                About <FaAngellist />
              </h1>
              <div className="about__info-p1">
                Dr. Biswarup Neogi presently attached with JIS College of
                Engineering (JIS Group, India) as an Associate Professor and
                Head of ECE Dept. in addition as a Convener of Institutional
                Innovation Council-JISCE under MHRD Innovation Cell (Govt. of
                India). As an Innovation Ambassador, Institutional Innovation
                Council – JISCE, he is enhancing the innovation activities of
                JISCE. He is the Coordinator of CII Centre for Innovation-JISCE.
                He is associated as a Mentor of Change of 02 ATL Lab under Atal
                Innovation Mission. He also received Visiting Scientist Post
                from Institute of Coal, Federal Research Center Coal and Coal
                Chemistry Siberian Branch Russian Academy of Science. He is the
                Executive Council Member of Maulana Abul Kalam Azad University
                of Technology(MAKAUT), formerly West Bengal University of
                Technology, (Govt. of WB).
              </div>
              <div className="about__info-p2">
                He received his PhD (Engineering) degree from Jadavpur
                University on the year 2011 before that M.Tech in ECE from
                Kalyani Govt. Engineering College on the year 2007 and B.E in
                ECE from UIT, Burdwan on the year 2005. Previously, He was
                awarded AICTE Career Award for Young Teacher on the year
                2014-15. He has also awarded “Best Young Scientist Award " in
                GEC Leadership Awards 2018.
              </div>
              <div className="info__contacts">
                <div className="row">
                  {state.map((info) => (
                    <div className="col-6">
                      <strong>{info.title}&nbsp;</strong>
                      <p>{info.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
