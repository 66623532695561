import React from 'react';
import {
  FaFacebookF,
  FaTwitter,
  FaPinterest,
  FaInstagram,
  FaPlay,
} from 'react-icons/fa';
const Contact = () => {
  return (
    <div className="contact">
      <div className="container">
        <div className="contactSection">
          <div className="row justifyConter">
            <div className="col-7">
              <div className="contactSection-logo">
                <img src="/images/logo.png" alt="" />
              </div>
              <span>
                <p>
                  Copyright 2021 @<b>Dr. Biswarup Neogi</b> || All Rights
                  Reserved || Designed with 💖 by <b>Latiful Mousom</b>
                </p>
              </span>
              <ul className="contactCircles">
                <li>
                  <FaFacebookF className="contactIcon" />
                </li>
                <li>
                  <FaTwitter className="contactIcon" />
                </li>
                <li>
                  <FaPinterest className="contactIcon" />
                </li>
                <li>
                  <FaInstagram className="contactIcon" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
