import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function favButton() {
  return (
    <div className="popout">
      <div className="btn">
        <a href="https://wa.me/919477751235" target="_blank">
          <FaWhatsapp className="wp-icon" />
        </a>
      </div>
    </div>
  );
}

export default favButton;
