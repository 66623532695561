import React from 'react';
import { FaAlignJustify } from 'react-icons/fa';
const Nav = () => {
  const [state, setState] = React.useState(true);
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar__container">
          <ul className="navbar__left">
            <div className="navbar__left-logo">
              <img src="./images/logo.png" alt="logo" />
            </div>
          </ul>
          {state ? (
            <ul className="navbar__right">
              <li>
                <a href="">Home</a>
              </li>

              <li>
                <a href="">Patent/IPR</a>
              </li>

              <li>
                <a href="">Publication</a>
              </li>

              <li>
                <a href="">Innovation & Startups</a>
              </li>

              <li>
                <a href="">Funded Projects</a>
              </li>
              <li>
                <a href="">Awards</a>
              </li>
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="toggle" onClick={() => setState(!state)}>
        <FaAlignJustify />
      </div>
    </nav>
  );
};

export default Nav;
