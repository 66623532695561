import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaSkype,
  FaLinkedinIn,
  FaResearchgate,
  FaGraduationCap,
} from 'react-icons/fa';
const Banner = () => {
  const [state] = React.useState({
    title: 'Dr. Biswarup Neogi',
    image: './images/hero-img.png',
  });
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="header__content">
              <div className="header__section">
                <ul className="header__ul">
                  <li>
                    <a href="https://scholar.google.co.in/citations?user=XO1-0tYAAAAJ&hl=en">
                      <FaGraduationCap className="headerIcon" />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.scopus.com/authid/detail.uri?authorId=37081527000">
                      <FaSkype className="headerIcon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/dr-biswarup-neogi-b0b21412/">
                      <FaLinkedinIn className="headerIcon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.researchgate.net/profile/Biswarup-Neogi">
                      <FaResearchgate className="headerIcon" />
                    </a>
                  </li>
                </ul>

                <div class="tm-textbox">
                  <h1>{state.title}</h1>
                  <p>Associate Professor & Head</p>
                  <p>Electronics & Comm. Engineering Dept.</p>
                  <p>Convener, Institutional Innovation Council – JISCE</p>
                  <p>
                    Innovation Ambassador, Institutional Innovation Council –
                    JISCE
                  </p>
                  <p>Mentor In-Charge, ATL Lab, ATAL Innovation Mission</p>
                  <p>
                    JIS College of Engineering, W. B. INDIA (NAAC A, NIRF Ranked
                    Autonomous Institution)
                  </p>
                  <p>
                    Visiting Scientist, Institute of Coal Federal Research
                    Center Coal and Coal Chemistry Siberian Branch Russian
                    Academy of Science, Russia
                  </p>
                </div>

                <div className="header__buttons">
                  <a href="" className="btn-cv btn-outline">
                    Download CV
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="banner__img">
              <img src={state.image} alt="man" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Banner;
