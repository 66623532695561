import React from 'react';
import {
  FaBook,
  FaAward,
  FaCopyright,
  FaParagraph,
  FaSearchDollar,
  FaRegistered,
  FaReplyd,
  FaUserGraduate,
  FaUniversity,
} from 'react-icons/fa';

const Services = () => {
  const [header] = React.useState({
    mainHeader: 'Highlights',
  });
  const [state] = React.useState([
    {
      id: 1,
      icon: <FaBook className="commonIcons" />,
      heading: 'Academics and Innovation',
      text: '14 Yrs. Experience in Academics and Innovation, 10 Yrs. Post PhD Experience.',
    },
    {
      id: 2,
      icon: <FaAward className="commonIcons" />,
      heading: 'Awards & Conference',
      text: '06 Govt. Grant Received for Research Projects, Awards & Conference.',
    },
    {
      id: 3,
      icon: <FaCopyright className="commonIcons" />,
      heading: 'Patents & Design',
      text: '39 Patents Filed and among all 03 Patents & 03 Design already Granted.',
    },
    {
      id: 4,
      icon: <FaParagraph className="commonIcons" />,
      heading: 'Publications',
      text: 'More than 100 Publications and a number of Scopus & Web of Science Publication.',
    },
    {
      id: 5,
      icon: <FaRegistered className="commonIcons" />,
      heading: 'Startup Incorporation',
      text: 'Experienced in Present Trend Innovation, Startup Incorporation & Consultancy.',
    },
    {
      id: 6,
      icon: <FaSearchDollar className="commonIcons" />,
      heading: 'Jinnovation Pvt. Ltd.',
      text: 'Experienced as an Intrapreneur of Jinnovation Pvt. Ltd. Under JISCE.',
    },

    {
      id: 7,
      icon: <FaUserGraduate className="commonIcons" />,
      heading: 'PhD supervisor',
      text: 'Supervising 07 PhD Students and 21 M.Tech Projects. ',
    },

    {
      id: 8,
      icon: <FaReplyd className="commonIcons" />,
      heading: 'Research',
      text: 'Associate Several International & National level Collaborative Research',
    },

    {
      id: 9,
      icon: <FaUniversity className="commonIcons" />,
      heading: 'Education to Excellence',
      text: 'Experienced in NAAC Criteria 1 and NIRF Perception. ',
    },
  ]);
  return (
    <div className="services">
      <div className="container">
        <div className="services__header">
          <div className="common">
            <div className="quotes">
              <blockquote>
                To Flourish my Innovative Ideas towards the Improvement of
                Society
              </blockquote>
              <cite>Biswarup Neogi</cite>
            </div>
            <h3 className="heading">{header.mainHeader}</h3>
            <h1 className="mainHeader">{header.subHeading}</h1>
            <p className="mainContent">{header.text}</p>
            <div className="commonBorder"></div>
          </div>

          <div className="row bgMain">
            {state.map((info) => (
              <div className="col-4 bgMain">
                <div className="services__box">
                  {info.icon}
                  <div className="services__box-header">{info.heading}</div>
                  <div className="services__box-p">{info.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="marquee-mobile">
        <section>
          <div class="scroll text-1">
            <div>
              Innovative <span>(Idea + Teaching + Reserch)</span> Innovative{' '}
              <span>(Idea + Teaching + Reserch)</span> Innovative{' '}
              <span>(Idea + Teaching + Reserch)</span> Innovative
              <span>(Idea + Teaching + Reserch)&nbsp;</span>
            </div>
            <div>
              Innovative <span>(Idea + Teaching + Reserch)</span> Innovative{' '}
              <span>(Idea + Teaching + Reserch)</span> Innovative{' '}
              <span>(Idea + Teaching + Reserch)</span> Innovative
              <span>(Idea + Teaching + Reserch)&nbsp;</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Services;
