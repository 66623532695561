import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows = [
  createData(
    'Control System Based Modelling & Simulation of ECG Signal',
    `Soumendu Bhattacharya (HOD, ECE Dept. KIEM)`,
    `MAKAUT Soul Supervising`,
    `Pre-Submission Qualified, Thesis will be submitted on December 2019.`
  ),
  createData(
    'Study the Performances and Control Analogy of Dexterous Arm Model.',
    `Swati Banerjee (Asst. Prof. , NIT, Agarpara)`,
    `MAKAUT Soul Supervising`,
    `Registered on 2013 Work on Progress (Delay Due to Medical Issues of candidate)`
  ),
  createData(
    'Study and Design on Lower Limb Prosthetic Control Introducing Simulation Method',
    `Susmita Das Asst. Prof. (NIT, Agarpara, WB, INDIA)`,
    `IIIT, Kalyani Joint Supervising With Dr. D. Nandi`,
    `Registered Nov 2020`
  ),
  createData(
    'Design on Human Respiratory System Model Introducing System Identifier Control Analogy',
    `Moupali Roy (Asst. Prof. NIT, Agarpara, WB, INDIA)`,
    `NIT Meghalaya Joint Supervising With Dr. P. Saha`,
    `Enrolled 2019`
  ),
  createData(
    'Nonlinear Control Approach for Human Limb Movement',
    `Pritam Chakraborty (Asst. Prof. , St. Merry Institute of Technology)`,
    `MAKAUT Joint SupervisingWith Dr. A.Das`,
    `Enrolled 2019`
  ),
  createData(
    'Music Pattern Detection and Singer Recognition in Indian perspective using Digital Signal Processing under the framework of Machine Intelligence: Some Approaches.',
    `Paramita Das (Asst. Prof. Amity University, Kolkata)`,
    `MAKAUT Joint Supervising With Dr.S.Gupta`,
    `Enrolled 2019`
  ),
  createData(
    `Data-driven Framework for Nursing Students' Motivation Regulation Strategies`,
    `Sourajit Ghosh (IINR, Kalyani)`,
    `JISU Joint Supervising With Dr.N. Dey`,
    `Enrolled 2020`
  ),
];

export default function CustomizedTables() {
  return (
    <div className="table">
      <TableContainer component={Paper} className="table-custom">
        <Typography className="text" variant="h6" gutterBottom component="div">
          PhD Thesis & M.Tech Project Supervised
        </Typography>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title of the Thesis</StyledTableCell>
              <StyledTableCell align="right">
                Name & Designation of Scholars
              </StyledTableCell>
              <StyledTableCell align="right">University</StyledTableCell>
              <StyledTableCell align="right">Current Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.calories}</StyledTableCell>
                <StyledTableCell align="right">{row.fat}</StyledTableCell>
                <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
